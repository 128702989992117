export enum SETTING_CATEGORIES {
  BASE = 1,
  AVIONICS,
  REGISTRY,
  UNITS,
  ETP_POLICIES,
  SPEED_SCHEDULE,
  GENERAL,
  FLIGHT_PLANNING_SERVICE,
  AIRFRAME,
}

export enum SETTING_ID {
  AIRCRAFT_COLOR = 1,
  ACARS_MANUFACTURER,
  REGISTRY_IDENTIFIER_COUNTRY,
  MODEL,
  ICAO_TYPE_DESIGNATOR,
  WAKE_TURBULENCE_CATEGORY,
  NOISE_CHAPTER,
  FUEL_TYPE,
  FIRE_CATEGORY,
  RANGE_UOM,
  WEIGHT_UOM,
  ICAO_AERODROME_REFERENCE_CODE,
  CATEGORIES,
  DISTANCE_UOM,
  SERIES,
  ENGINE_TYPE,
  ETP_SCENARIO_TYPE,
  ETP_TIME_LIMIT,
  AIRCRAFT_MODIFICATION,
  ETP_ALT_DESCENT_PROFILE,
  ETP_SCENARIO_ENGINE,
  ETP_LEVELS,
  ETP_MAIN_DESCENT,
  ETP_FINAL_DESCENT,
  FLIGHT_PLAN_FORMAT_STATUS,
  SUB_CATEGORY,
  ETP_APU_BURN_METHOD,
  ETP_PENALTY_APPLY,
  ETP_CRUISE_PROFILE,
  ETP_HOLD_METHOD,
  ETP_PENALTY_BIAS_TYPE,
  AIRFRAME_STATUS,
  ETP_PENALTY_CATEGORY,
  CRUISE_SCHEDULE,
  AIRCRAFT_MAKE,
  WIND_UOM,
  CLIMB_SCHEDULE,
  FUEL_RESERVE_POLICY,
  ACCESS_LEVEL,
  SOURCE_TYPE,
  DESCENT_SCHEDULE,
  HOLD_SCHEDULE,
  ACARS_MODEL,
  FMS_MANUFACTURER,
  ACARS_SOFTWARE_VERSION,
  AES_MANUFACTURER,
  FMS_MODEL,
  ACARS_MESSAGE_SET,
  AES_MODEL,
  RAIM_REPORT_TYPE,
  FMS_SOFTWARE_VERSION,
  NFP_FUEL_RESERVE_TYPE,
  RAIM_RECEIVER_TYPE,
  STC_MANUFACTURE,
  AIRCRAFT_NOISE_TYPE,
  NOISE_DATA_TYPE_CERTIFICATION,
  NOISE_CHAPTER_CONFIGURATION,
  RADIO,
  ACAS,
  TRANSPONDER,
  MILITARY_DESIGNATION,
  OTHER_NAME,
  WAKE_TURBULENCE_GROUP,
  POPULAR_NAME,
  PROPULSION_TYPE,
  FLIGHT_PLANNING_SERVICE_TYPE,
  DELIVERY_PACKAGE,
  RUNWAY_ANALYSIS,
  UPLINK_VENDOR,
  CATERING_HEATING_ELEMENT,
  OUTER_MAIN_GEAR_WHEEL_SPAN,
}
