import { baseGridFiltersDictionary } from '@wings/shared';
import { AIRFRAME_FILTERS } from '../Shared';
import { IAPIFilterDictionary } from '@wings-shared/core';

export const gridFilters: IAPIFilterDictionary<AIRFRAME_FILTERS>[] = [
  ...baseGridFiltersDictionary<AIRFRAME_FILTERS>(),
  {
    columnId: 'serialNumber',
    apiPropertyName: 'SerialNumber',
    uiFilterType: AIRFRAME_FILTERS.SERIAL_NUMBER,
  },
  {
    columnId: 'aircraftVariation.series',
    apiPropertyName: 'AircraftVariation.Series.Name',
    uiFilterType: AIRFRAME_FILTERS.SERIES,
  },
  {
    columnId: 'airframeStatus',
    apiPropertyName: 'AirframeStatus.Name',
    uiFilterType: AIRFRAME_FILTERS.AIRFRAME_STATUS,
  },
  {
    columnId: 'airworthinessRecentDate',
    apiPropertyName: 'AirworthinessRecentDate',
    uiFilterType: AIRFRAME_FILTERS.AIRWORTHINESS_DATE,
  },
  {
    columnId: 'aircraftVariation.cappsId',
    apiPropertyName: 'AircraftVariation.CAPPSId',
    uiFilterType: AIRFRAME_FILTERS.VARIATION,
  },
  {
    columnId: 'airframeRegistry.registry',
    apiPropertyName: 'AirframeRegistry.Registry.Name',
    uiFilterType: AIRFRAME_FILTERS.REGISTRY,
  },
  {
    columnId: 'airframeWeightAndLength.maxTakeOffWeight',
    apiPropertyName: 'AirframeWeightAndLength.MaxTakeOffWeight',
    uiFilterType: AIRFRAME_FILTERS.MTOW,
  },
  {
    columnId: 'crewSeatCap',
    apiPropertyName: 'CrewSeatCap',
  },
  {
    columnId: 'paxSeatCap',
    apiPropertyName: 'PaxSeatCap',
  },
  { columnId: 'manufactureDate', apiPropertyName: 'ManufactureDate' },
  { columnId: 'temporaryEngineDate', apiPropertyName: 'TemporaryEngineDate' },
];
