import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Dialog } from '@uvgo-shared/dialog';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { useStyles } from './VariationSearchDialog.styles';
import { AircraftVariationStore, AircraftVariationModel } from '../../../Shared';
import { finalize } from 'rxjs/operators';
import { UIStore } from '@wings-shared/core';
import VariationSearch from '../VariationSearch/VariationSearch';

interface Props {
  aircraftVariationStore?: AircraftVariationStore;
  onSelect: (selectedVariation: AircraftVariationModel) => void;
}

const VariationSearchDialog: FC<Props> = ({ aircraftVariationStore, onSelect }) => {
  const classes = useStyles();
  const _variationStore = aircraftVariationStore as AircraftVariationStore;

  /* istanbul ignore next */
  useEffect(() => {
    loadInitialData();
  }, []);

  /* istanbul ignore next */
  const loadInitialData = (): void => {
    UIStore.setPageLoader(true);
    aircraftVariationStore
      ?.getAircraftVariations()
      .pipe(finalize(() => UIStore.setPageLoader(false)))
      .subscribe();
  };

  return (
    <Dialog
      title={'Select Aircraft Type'}
      open={true}
      isLoading={() => UIStore.pageLoading}
      classes={{
        paperSize: classes.paperSize,
        header: classes.headerWrapper,
      }}
      onClose={() => ModalStore.close()}
      dialogContent={() => <VariationSearch data={_variationStore.aircraftVariations} onSelect={onSelect} />}
    />
  );
};
export default observer(VariationSearchDialog);
